@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    --pink: #e37ad5;
    --cyan: #94b6f9;
    font-family: "Libre Franklin", sans-serif;
}
p {
    font-size: 1.2rem;
    font-weight: 500;
}
.jumbo-container {
    position: relative;
    overflow: hidden;
    background-image: radial-gradient(
            circle at 50% 50%,
            var(--cyan) 0%,
            #00000000 60%
        ),
        radial-gradient(circle at 50% 110%, var(--pink) 0%, #00000000 70%);
}
.jumbo-container::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    background-image: conic-gradient(
            from 277deg at 50% 100%,
            #000000ff 1%,
            #00000000 5%,
            #00000000 54%,
            #000000ff 54%,
            #000000ff 100%
        ),
        conic-gradient(
            from 83deg at 50% 100%,
            #000000ff 0%,
            #000000ff 45%,
            #00000000 46%,
            #00000000 95%,
            #000000ff 99%
        );
    z-index: 2;
}
.jumbo-container light-moving-scene {
    display: block;
    height: 70vh;
    margin-bottom: -1px;
    clip-path: polygon(0 0, 100% 0%, 100% 99%, 50% 100%, 0 99%);
}
.logo-container {
    height: 80px;
}
.logo-container img {
    height: 100%;
}
img.img-flag {
    height: 20px;
}

.shadow-gif {
    filter: drop-shadow(-16px 19px 26px #00000075);
}

img.goals {
    padding: 2em;
    max-width: 240px;
}

a.goal {
    display: block;
    margin-top: 1em;
    font-weight: bold;
}

.claim-container h1 {
    font-size: 3rem;
    line-height: 3.5rem;
    font-weight: 300;
}
.title-gradient {
    background: linear-gradient(to right, var(--pink) 25%, var(--cyan) 75%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.quote-text {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 2.4rem;
    letter-spacing: 0.9px;
    background: radial-gradient(
        ellipse at 65% 135%,
        var(--cyan) 40%,
        white 60%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.collab-logos {
    font-size: 40px;
    color: darkgray;
    text-align: center;
}
.collab-logos img {
    width: 100%;
}

.col-content {
    max-width: 480px;
}

.col-content-wide {
    max-width: 680px;
}

.col-content p, .col-content-wide p {
    margin-top: 1rem;
}

.section-text {
    filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.2));
}

.section-text h2 {
    font-size: 2.8rem;
    line-height: 3.7rem;
    margin-bottom: 20px;
    font-weight: 600;
    letter-spacing: 1.1px;
}
.section-text h3 {
    font-size: 1.8rem;
    font-weight: 500;
}

.section-highlight {
    background-color: #0007;
    backdrop-filter: blur(10px);
}

.section-highlight a {

}

.section-text h4 {
    font-size: 130%;
    margin: 1em 0 0.5em;
    font-weight: 800;
}
.section-text.cyan h3 {
    color: var(--pink);
}
.section-text.pink h3 {
    color: var(--cyan);
}
.section-text.cyan h2 {
    background-image: radial-gradient(
        ellipse at 65% 135%,
        var(--pink) 20%,
        white 60%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.section-text.pink h2 {
    background-image: radial-gradient(
        ellipse at 65% 135%,
        var(--cyan) 20%,
        white 60%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.double-bg {
    background-image: linear-gradient(6deg, #00ffff00 57%, #000000ff 84%),
        linear-gradient(185deg, #00ffff00 42%, #000000ff 86%),
        radial-gradient(circle at 2% 87%, var(--cyan) 0%, #00ffff00 34%),
        radial-gradient(circle at 17% 50%, var(--pink) 0%, #073aff00 74%),
        radial-gradient(circle at 111% 107%, var(--pink) 0%, #073aff00 60%),
        radial-gradient(circle at 75% 52%, var(--cyan) 14%, #00ffff00 99%),
        linear-gradient(
            185deg,
            #000000ff 1%,
            #000000ff 42%,
            #ffffff45 43%,
            #ffffff45 100%
        );
    background-repeat: no-repeat;
}
.double-bg-inverted {
    background-image: linear-gradient(6deg, #00ffff00 57%, #000000ff 84%),
        linear-gradient(185deg, #00ffff00 42%, #000000ff 91%),
        radial-gradient(circle at 2% 87%, var(--pink) 0%, #00ffff00 34%),
        radial-gradient(circle at 17% 50%, var(--cyan) 0%, #073aff00 74%),
        radial-gradient(circle at 111% 107%, var(--cyan) 0%, #073aff00 60%),
        radial-gradient(circle at 75% 52%, var(--pink) 14%, #00ffff00 99%),
        linear-gradient(
            185deg,
            #000000ff 1%,
            #000000ff 42%,
            #ffffff45 43%,
            #ffffff45 100%
        );
    background-repeat: no-repeat;
}

.payoff-bg {
    background-color: white;
    background-image: linear-gradient(
            355deg,
            #000000ff 26%,
            #0000008f 42%,
            #0000001a 62%,
            #0000000d 66%,
            #0000000a 70%,
            #00000000 100%
        ),
        radial-gradient(circle at 85% 22%, var(--cyan) 12%, #00000000 100%),
        radial-gradient(circle at 21% 29%, var(--pink) 12%, #00000000 93%);
}

.payoff-text {
    text-align: center;
}

.payoff-text h2 div:nth-child(1) {
    color: black;
    font-size: 2.5rem;
    font-weight: 500;
}
.payoff-text h2 div:nth-child(2) {
    font-size: 145px;
    line-height: 145px;
    font-weight: 600;
    margin-bottom: -60px;
    background: linear-gradient(to top, #00000000 5%, white 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.payoff-text h2 div:nth-child(3) {
    font-size: 74px;
    text-shadow: 0px -8px 14px #00000080;
}
.payoff-text h3 {
    font-size: 1.8rem;
    margin-top: 20px;
    font-weight: 500;
}
.side-stats h2 {
    font-size: 120px;
    line-height: 120px;
    font-weight: 500;
    margin-top: 20px;
}

.vertical-bg {
    background-image: radial-gradient(
            circle at 6% 93%,
            var(--cyan) 10%,
            #00000000 70%
        ),
        radial-gradient(circle at 3% 5%, var(--pink) 30%, #00000000 70%),
        linear-gradient(90deg, rgb(0, 0, 0) 30%, #00000000 65%);
        background-attachment: fixed;

}

.vertical-bg video {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    object-fit: cover;
}

@media screen and (min-width: 768px) {
    .claim-container h1 {
        font-size: 4rem;
        line-height: 4.5rem;
    }
}
