*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.relative {
  position: relative;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-auto {
  margin-top: auto;
}

.flex {
  display: flex;
}

.min-h-screen {
  min-height: 100vh;
}

.w-full {
  width: 100%;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-content-center {
  place-content: center;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-2xl {
  border-radius: 1rem;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.p-12 {
  padding: 3rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.text-center {
  text-align: center;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-semibold {
  font-weight: 600;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

body {
  --pink: #e37ad5;
  --cyan: #94b6f9;
  font-family: Libre Franklin, sans-serif;
}

p {
  font-size: 1.2rem;
  font-weight: 500;
}

.jumbo-container {
  background-image: radial-gradient(circle at 50% 50%, var(--cyan) 0%, #0000 60%), radial-gradient(circle at 50% 110%, var(--pink) 0%, #0000 70%);
  position: relative;
  overflow: hidden;
}

.jumbo-container:after {
  content: "";
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 2;
  background-image: conic-gradient(from 277deg at 50% 100%, #000 1%, #0000 5% 54%, #000 54% 100%), conic-gradient(from 83deg at 50% 100%, #000 0% 45%, #0000 46% 95%, #000 99%);
  position: absolute;
  bottom: 0;
  left: 0;
}

.jumbo-container light-moving-scene {
  height: 70vh;
  clip-path: polygon(0 0, 100% 0%, 100% 99%, 50% 100%, 0 99%);
  margin-bottom: -1px;
  display: block;
}

.logo-container {
  height: 80px;
}

.logo-container img {
  height: 100%;
}

img.img-flag {
  height: 20px;
}

.shadow-gif {
  filter: drop-shadow(-16px 19px 26px #00000075);
}

img.goals {
  max-width: 240px;
  padding: 2em;
}

a.goal {
  margin-top: 1em;
  font-weight: bold;
  display: block;
}

.claim-container h1 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 3.5rem;
}

.title-gradient {
  background: linear-gradient(to right, var(--pink) 25%, var(--cyan) 75%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.quote-text {
  letter-spacing: .9px;
  background: radial-gradient(ellipse at 65% 135%, var(--cyan) 40%, white 60%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2.4rem;
}

.collab-logos {
  color: #a9a9a9;
  text-align: center;
  font-size: 40px;
}

.collab-logos img {
  width: 100%;
}

.col-content {
  max-width: 480px;
}

.col-content-wide {
  max-width: 680px;
}

.col-content p, .col-content-wide p {
  margin-top: 1rem;
}

.section-text {
  filter: drop-shadow(2px 2px 10px #0003);
}

.section-text h2 {
  letter-spacing: 1.1px;
  margin-bottom: 20px;
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 3.7rem;
}

.section-text h3 {
  font-size: 1.8rem;
  font-weight: 500;
}

.section-highlight {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #0007;
}

.section-text h4 {
  margin: 1em 0 .5em;
  font-size: 130%;
  font-weight: 800;
}

.section-text.cyan h3 {
  color: var(--pink);
}

.section-text.pink h3 {
  color: var(--cyan);
}

.section-text.cyan h2 {
  background-image: radial-gradient(ellipse at 65% 135%, var(--pink) 20%, white 60%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-text.pink h2 {
  background-image: radial-gradient(ellipse at 65% 135%, var(--cyan) 20%, white 60%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.double-bg {
  background-image: linear-gradient(6deg, #0ff0 57%, #000 84%), linear-gradient(185deg, #0ff0 42%, #000 86%), radial-gradient(circle at 2% 87%, var(--cyan) 0%, #0ff0 34%), radial-gradient(circle at 17% 50%, var(--pink) 0%, #073aff00 74%), radial-gradient(circle at 111% 107%, var(--pink) 0%, #073aff00 60%), radial-gradient(circle at 75% 52%, var(--cyan) 14%, #0ff0 99%), linear-gradient(185deg, #000 1%, #000 42%, #ffffff45 43%, #ffffff45 100%);
  background-repeat: no-repeat;
}

.double-bg-inverted {
  background-image: linear-gradient(6deg, #0ff0 57%, #000 84%), linear-gradient(185deg, #0ff0 42%, #000 91%), radial-gradient(circle at 2% 87%, var(--pink) 0%, #0ff0 34%), radial-gradient(circle at 17% 50%, var(--cyan) 0%, #073aff00 74%), radial-gradient(circle at 111% 107%, var(--cyan) 0%, #073aff00 60%), radial-gradient(circle at 75% 52%, var(--pink) 14%, #0ff0 99%), linear-gradient(185deg, #000 1%, #000 42%, #ffffff45 43%, #ffffff45 100%);
  background-repeat: no-repeat;
}

.payoff-bg {
  background-color: #fff;
  background-image: linear-gradient(355deg, #000 26%, #0000008f 42%, #0000001a 62%, #0000000d 66%, #0000000a 70%, #0000 100%), radial-gradient(circle at 85% 22%, var(--cyan) 12%, #0000 100%), radial-gradient(circle at 21% 29%, var(--pink) 12%, #0000 93%);
}

.payoff-text {
  text-align: center;
}

.payoff-text h2 div:nth-child(1) {
  color: #000;
  font-size: 2.5rem;
  font-weight: 500;
}

.payoff-text h2 div:nth-child(2) {
  background: linear-gradient(to top, #0000 5%, #fff 50%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: -60px;
  font-size: 145px;
  font-weight: 600;
  line-height: 145px;
}

.payoff-text h2 div:nth-child(3) {
  text-shadow: 0 -8px 14px #00000080;
  font-size: 74px;
}

.payoff-text h3 {
  margin-top: 20px;
  font-size: 1.8rem;
  font-weight: 500;
}

.side-stats h2 {
  margin-top: 20px;
  font-size: 120px;
  font-weight: 500;
  line-height: 120px;
}

.vertical-bg {
  background-image: radial-gradient(circle at 6% 93%, var(--cyan) 10%, #0000 70%), radial-gradient(circle at 3% 5%, var(--pink) 30%, #0000 70%), linear-gradient(90deg, #000 30%, #0000 65%);
  background-attachment: fixed;
}

.vertical-bg video {
  height: 100%;
  width: 100%;
  z-index: -1;
  object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (min-width: 768px) {
  .claim-container h1 {
    font-size: 4rem;
    line-height: 4.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

/*# sourceMappingURL=index.0ccd49b2.css.map */
